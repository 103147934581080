import React from 'react'
import Layout from '~/layouts/layout'
import SEO from '~/components/seo'

const materialen = () => {
  return (
    <Layout language="en" en="materials" nl="materialen">
      <SEO
        title="Materials"
        keywords={[`valta`, `lamp`, `licht`, `materials`]}
      />
      <div className="articleHero gridFullWidth">
        <h1>Materials</h1>
      </div>
      <div
        className="articleHero gridFullWidth"
        style={{
          justifyContent: `center`,
          backgroundColor: `var(--light-grey)`,
        }}
      >
        <h2>PLA</h2>
      </div>
      <div className="grid4 materialBlock">
        <h3>3D-printed material</h3>
        <img src={`${require('~/images/3dprinter.jpg')}`} loading="lazy" />
        <p>Using the latest technologies</p>
      </div>
      <div className="grid4 materialBlock">
        <h3>Plant-based</h3>
        <img src={`${require('~/images/corn.jpg')}`} loading="lazy" />
        <p>Made from plant-based sources, like corn</p>
      </div>
      <div className="grid4 materialBlock">
        <h3>Environmentally friendly</h3>
        <img src={`${require('~/images/filament.jpg')}`} loading="lazy" />
        <p>PLA is an environmentally friendly and compostable platic</p>
      </div>
      <div
        className="articleHero gridFullWidth"
        style={{
          justifyContent: `center`,
          backgroundColor: `var(--light-grey)`,
        }}
      >
        <h2>Oak Wood</h2>
      </div>
      <div className="grid4 materialBlock">
        <h3>Locally sourced</h3>
        <img src={`${require('~/images/sawmill.jpg')}`} loading="lazy" />
        <p>Bought from a local lumber mill</p>
      </div>
      <div className="grid4 materialBlock">
        <h3>European White Oak</h3>
        <img src={`${require('~/images/eik.jpg')}`} loading="lazy" />
        <p>We handpick the best pieces of lumber</p>
      </div>
      <div className="grid4 materialBlock">
        <h3>Planed and oiled</h3>
        <img src={`${require('~/images/oak.jpg')}`} loading="lazy" />
        <p>
          We take care that each board gets planed and oiled for a perfectly
          smooth finish
        </p>
      </div>
    </Layout>
  )
}

export default materialen
